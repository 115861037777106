<template>
  <ValidationProvider
    ref="fieldAccountType"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="iAccountTypeId"
        :error-messages="errors"
        :items="items"
        :label="$t(label)"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :success="valid"
        clearable
        dense
        hide-details="auto"
        outlined
        v-bind="$attrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>
<script lang="ts">
import {
  AccountTypeCollection,
  AccountTypeData,
} from "@planetadeleste/vue-mc-gw";
import { Component, Prop, PropSync, VModel, Vue } from "vue-property-decorator";
import { find, isString, map, startsWith } from "lodash";
import { SelectItemData } from "@/types/utils";

@Component
export default class AccountTypeSelect extends Vue {
  @VModel({ type: [Number, String] }) iAccountTypeId!: number | string;
  @PropSync("item", { type: Object }) obItemSelected!: AccountTypeData;
  @Prop({ type: String, default: "account.type" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly prefixed!: boolean;

  obCollection: AccountTypeCollection = new AccountTypeCollection();

  get items() {
    return map(
      this.obCollection.getModelList(),
      (obItem: SelectItemData<string | number, string, AccountTypeData>) => {
        obItem.text = obItem.name;
        obItem.value = this.prefixed ? `accounttype.${obItem.code}` : obItem.id;

        return obItem;
      }
    );
  }

  get loading() {
    return this.obCollection.loading;
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    this.obCollection.clear();

    await this.obCollection.list();
  }

  onSelect(sValue: number | string) {
    let obItem: Partial<SelectItemData | AccountTypeData> | undefined =
      undefined;

    if (
      this.prefixed &&
      isString(sValue) &&
      startsWith(sValue, "accounttype")
    ) {
      const sCode = sValue.replace("accounttype.", "");
      obItem = this.obCollection.find({ code: sCode });
    } else {
      obItem = find(this.items, { value: sValue });
    }

    if (obItem) {
      this.$emit("update:item", obItem);
      this.$emit("change", obItem);
    }
  }
}
</script>
